/* Converted Tailwind Config to sass */
$animation-none: none;
$animation-spin: spin 1s linear infinite;
$animation-ping: (ping 1s cubic-bezier(0, 0, 0.2, 1) infinite);
$animation-pulse: (pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite);
$animation-bounce: bounce 1s infinite;

$backgroundColor-transparent: transparent;
$backgroundColor-current: currentColor;
$backgroundColor-primary: #ea580c;
$backgroundColor-danger: #DC2626;
$backgroundColor-black: #000;
$backgroundColor-orange-50: #fff7ed;
$backgroundColor-orange-100: #ffedd5;
$backgroundColor-orange-200: #fed7aa;
$backgroundColor-orange-300: #fdba74;
$backgroundColor-orange-400: #fb923c;
$backgroundColor-orange-500: #f97316;
$backgroundColor-orange-600: #ea580c;
$backgroundColor-orange-700: #c2410c;
$backgroundColor-orange-800: #9a3412;
$backgroundColor-orange-900: #7c2d12;
$backgroundColor-white: #fff;
$backgroundColor-gray-50: #f9fafb;
$backgroundColor-gray-100: #f3f4f6;
$backgroundColor-gray-200: #e5e7eb;
$backgroundColor-gray-300: #d1d5db;
$backgroundColor-gray-400: #9ca3af;
$backgroundColor-gray-500: #6b7280;
$backgroundColor-gray-600: #4b5563;
$backgroundColor-gray-700: #374151;
$backgroundColor-gray-800: #1f2937;
$backgroundColor-gray-900: #111827;
$backgroundColor-red-50: #fef2f2;
$backgroundColor-red-100: #fee2e2;
$backgroundColor-red-200: #fecaca;
$backgroundColor-red-300: #fca5a5;
$backgroundColor-red-400: #f87171;
$backgroundColor-red-500: #ef4444;
$backgroundColor-red-600: #dc2626;
$backgroundColor-red-700: #b91c1c;
$backgroundColor-red-800: #991b1b;
$backgroundColor-red-900: #7f1d1d;
$backgroundColor-yellow-50: #fffbeb;
$backgroundColor-yellow-100: #fef3c7;
$backgroundColor-yellow-200: #fde68a;
$backgroundColor-yellow-300: #fcd34d;
$backgroundColor-yellow-400: #fbbf24;
$backgroundColor-yellow-500: #f59e0b;
$backgroundColor-yellow-600: #d97706;
$backgroundColor-yellow-700: #b45309;
$backgroundColor-yellow-800: #92400e;
$backgroundColor-yellow-900: #78350f;
$backgroundColor-green-50: #ecfdf5;
$backgroundColor-green-100: #d1fae5;
$backgroundColor-green-200: #a7f3d0;
$backgroundColor-green-300: #6ee7b7;
$backgroundColor-green-400: #34d399;
$backgroundColor-green-500: #10b981;
$backgroundColor-green-600: #059669;
$backgroundColor-green-700: #047857;
$backgroundColor-green-800: #065f46;
$backgroundColor-green-900: #064e3b;
$backgroundColor-blue-50: #eff6ff;
$backgroundColor-blue-100: #dbeafe;
$backgroundColor-blue-200: #bfdbfe;
$backgroundColor-blue-300: #93c5fd;
$backgroundColor-blue-400: #60a5fa;
$backgroundColor-blue-500: #3b82f6;
$backgroundColor-blue-600: #2563eb;
$backgroundColor-blue-700: #1d4ed8;
$backgroundColor-blue-800: #1e40af;
$backgroundColor-blue-900: #1e3a8a;
$backgroundColor-indigo-50: #eef2ff;
$backgroundColor-indigo-100: #e0e7ff;
$backgroundColor-indigo-200: #c7d2fe;
$backgroundColor-indigo-300: #a5b4fc;
$backgroundColor-indigo-400: #818cf8;
$backgroundColor-indigo-500: #6366f1;
$backgroundColor-indigo-600: #4f46e5;
$backgroundColor-indigo-700: #4338ca;
$backgroundColor-indigo-800: #3730a3;
$backgroundColor-indigo-900: #312e81;
$backgroundColor-purple-50: #f5f3ff;
$backgroundColor-purple-100: #ede9fe;
$backgroundColor-purple-200: #ddd6fe;
$backgroundColor-purple-300: #c4b5fd;
$backgroundColor-purple-400: #a78bfa;
$backgroundColor-purple-500: #8b5cf6;
$backgroundColor-purple-600: #7c3aed;
$backgroundColor-purple-700: #6d28d9;
$backgroundColor-purple-800: #5b21b6;
$backgroundColor-purple-900: #4c1d95;
$backgroundColor-pink-50: #fdf2f8;
$backgroundColor-pink-100: #fce7f3;
$backgroundColor-pink-200: #fbcfe8;
$backgroundColor-pink-300: #f9a8d4;
$backgroundColor-pink-400: #f472b6;
$backgroundColor-pink-500: #ec4899;
$backgroundColor-pink-600: #db2777;
$backgroundColor-pink-700: #be185d;
$backgroundColor-pink-800: #9d174d;
$backgroundColor-pink-900: #831843;

$backgroundImage-none: none;
$backgroundImage-gradient-to-t: (linear-gradient(to top, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-tr: (linear-gradient(to top right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-r: (linear-gradient(to right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-br: (linear-gradient(to bottom right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-b: (linear-gradient(to bottom, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-bl: (linear-gradient(to bottom left, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-l: (linear-gradient(to left, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-tl: (linear-gradient(to top left, var(--tw-gradient-stops)));

$backgroundOpacity-0: 0;
$backgroundOpacity-5: 0.05;
$backgroundOpacity-10: 0.1;
$backgroundOpacity-20: 0.2;
$backgroundOpacity-25: 0.25;
$backgroundOpacity-30: 0.3;
$backgroundOpacity-40: 0.4;
$backgroundOpacity-50: 0.5;
$backgroundOpacity-60: 0.6;
$backgroundOpacity-70: 0.7;
$backgroundOpacity-75: 0.75;
$backgroundOpacity-80: 0.8;
$backgroundOpacity-90: 0.9;
$backgroundOpacity-95: 0.95;
$backgroundOpacity-100: 1;

$backgroundPosition-bottom: bottom;
$backgroundPosition-center: center;
$backgroundPosition-left: left;
$backgroundPosition-left-bottom: left bottom;
$backgroundPosition-left-top: left top;
$backgroundPosition-right: right;
$backgroundPosition-right-bottom: right bottom;
$backgroundPosition-right-top: right top;
$backgroundPosition-top: top;

$backgroundSize-auto: auto;
$backgroundSize-cover: cover;
$backgroundSize-contain: contain;

$borderColor-transparent: transparent;
$borderColor-current: currentColor;
$borderColor-primary: #ea580c;
$borderColor-danger: #DC2626;
$borderColor-black: #000;
$borderColor-orange-50: #fff7ed;
$borderColor-orange-100: #ffedd5;
$borderColor-orange-200: #fed7aa;
$borderColor-orange-300: #fdba74;
$borderColor-orange-400: #fb923c;
$borderColor-orange-500: #f97316;
$borderColor-orange-600: #ea580c;
$borderColor-orange-700: #c2410c;
$borderColor-orange-800: #9a3412;
$borderColor-orange-900: #7c2d12;
$borderColor-white: #fff;
$borderColor-gray-50: #f9fafb;
$borderColor-gray-100: #f3f4f6;
$borderColor-gray-200: #e5e7eb;
$borderColor-gray-300: #d1d5db;
$borderColor-gray-400: #9ca3af;
$borderColor-gray-500: #6b7280;
$borderColor-gray-600: #4b5563;
$borderColor-gray-700: #374151;
$borderColor-gray-800: #1f2937;
$borderColor-gray-900: #111827;
$borderColor-red-50: #fef2f2;
$borderColor-red-100: #fee2e2;
$borderColor-red-200: #fecaca;
$borderColor-red-300: #fca5a5;
$borderColor-red-400: #f87171;
$borderColor-red-500: #ef4444;
$borderColor-red-600: #dc2626;
$borderColor-red-700: #b91c1c;
$borderColor-red-800: #991b1b;
$borderColor-red-900: #7f1d1d;
$borderColor-yellow-50: #fffbeb;
$borderColor-yellow-100: #fef3c7;
$borderColor-yellow-200: #fde68a;
$borderColor-yellow-300: #fcd34d;
$borderColor-yellow-400: #fbbf24;
$borderColor-yellow-500: #f59e0b;
$borderColor-yellow-600: #d97706;
$borderColor-yellow-700: #b45309;
$borderColor-yellow-800: #92400e;
$borderColor-yellow-900: #78350f;
$borderColor-green-50: #ecfdf5;
$borderColor-green-100: #d1fae5;
$borderColor-green-200: #a7f3d0;
$borderColor-green-300: #6ee7b7;
$borderColor-green-400: #34d399;
$borderColor-green-500: #10b981;
$borderColor-green-600: #059669;
$borderColor-green-700: #047857;
$borderColor-green-800: #065f46;
$borderColor-green-900: #064e3b;
$borderColor-blue-50: #eff6ff;
$borderColor-blue-100: #dbeafe;
$borderColor-blue-200: #bfdbfe;
$borderColor-blue-300: #93c5fd;
$borderColor-blue-400: #60a5fa;
$borderColor-blue-500: #3b82f6;
$borderColor-blue-600: #2563eb;
$borderColor-blue-700: #1d4ed8;
$borderColor-blue-800: #1e40af;
$borderColor-blue-900: #1e3a8a;
$borderColor-indigo-50: #eef2ff;
$borderColor-indigo-100: #e0e7ff;
$borderColor-indigo-200: #c7d2fe;
$borderColor-indigo-300: #a5b4fc;
$borderColor-indigo-400: #818cf8;
$borderColor-indigo-500: #6366f1;
$borderColor-indigo-600: #4f46e5;
$borderColor-indigo-700: #4338ca;
$borderColor-indigo-800: #3730a3;
$borderColor-indigo-900: #312e81;
$borderColor-purple-50: #f5f3ff;
$borderColor-purple-100: #ede9fe;
$borderColor-purple-200: #ddd6fe;
$borderColor-purple-300: #c4b5fd;
$borderColor-purple-400: #a78bfa;
$borderColor-purple-500: #8b5cf6;
$borderColor-purple-600: #7c3aed;
$borderColor-purple-700: #6d28d9;
$borderColor-purple-800: #5b21b6;
$borderColor-purple-900: #4c1d95;
$borderColor-pink-50: #fdf2f8;
$borderColor-pink-100: #fce7f3;
$borderColor-pink-200: #fbcfe8;
$borderColor-pink-300: #f9a8d4;
$borderColor-pink-400: #f472b6;
$borderColor-pink-500: #ec4899;
$borderColor-pink-600: #db2777;
$borderColor-pink-700: #be185d;
$borderColor-pink-800: #9d174d;
$borderColor-pink-900: #831843;
$borderColor-DEFAULT: #e5e7eb;

$borderOpacity-0: 0;
$borderOpacity-5: 0.05;
$borderOpacity-10: 0.1;
$borderOpacity-20: 0.2;
$borderOpacity-25: 0.25;
$borderOpacity-30: 0.3;
$borderOpacity-40: 0.4;
$borderOpacity-50: 0.5;
$borderOpacity-60: 0.6;
$borderOpacity-70: 0.7;
$borderOpacity-75: 0.75;
$borderOpacity-80: 0.8;
$borderOpacity-90: 0.9;
$borderOpacity-95: 0.95;
$borderOpacity-100: 1;

$borderRadius-none: 0px;
$borderRadius-sm: 0.125rem;
$borderRadius-DEFAULT: 0.25rem;
$borderRadius-md: 0.375rem;
$borderRadius-lg: 0.5rem;
$borderRadius-xl: 0.75rem;
$borderRadius-2xl: 1rem;
$borderRadius-3xl: 1.5rem;
$borderRadius-full: 9999px;

$borderWidth-0: 0px;
$borderWidth-2: 2px;
$borderWidth-4: 4px;
$borderWidth-8: 8px;
$borderWidth-DEFAULT: 1px;

$boxShadow-sm: (0 1px 2px 0 rgba(0, 0, 0, 0.05));
$boxShadow-DEFAULT: (0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06));
$boxShadow-md: (0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06));
$boxShadow-lg: (0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05));
$boxShadow-xl: (0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04));
$boxShadow-2xl: (0 16px 64px rgba(0, 0, 0, 0.06), 0 12px 24px rgba(0, 0, 0, 0.04), 0px 6px 8px rgba(0, 0, 0, 0.02));
$boxShadow-inner: (inset 0 2px 4px 0 rgba(0, 0, 0, 0.06));
$boxShadow-none: none;


$cursor-auto: auto;
$cursor-default: default;
$cursor-pointer: pointer;
$cursor-wait: wait;
$cursor-text: text;
$cursor-move: move;
$cursor-not-allowed: not-allowed;

$divideColor-transparent: transparent;
$divideColor-current: currentColor;
$divideColor-primary: #ea580c;
$divideColor-danger: #DC2626;
$divideColor-black: #000;
$divideColor-orange-50: #fff7ed;
$divideColor-orange-100: #ffedd5;
$divideColor-orange-200: #fed7aa;
$divideColor-orange-300: #fdba74;
$divideColor-orange-400: #fb923c;
$divideColor-orange-500: #f97316;
$divideColor-orange-600: #ea580c;
$divideColor-orange-700: #c2410c;
$divideColor-orange-800: #9a3412;
$divideColor-orange-900: #7c2d12;
$divideColor-white: #fff;
$divideColor-gray-50: #f9fafb;
$divideColor-gray-100: #f3f4f6;
$divideColor-gray-200: #e5e7eb;
$divideColor-gray-300: #d1d5db;
$divideColor-gray-400: #9ca3af;
$divideColor-gray-500: #6b7280;
$divideColor-gray-600: #4b5563;
$divideColor-gray-700: #374151;
$divideColor-gray-800: #1f2937;
$divideColor-gray-900: #111827;
$divideColor-red-50: #fef2f2;
$divideColor-red-100: #fee2e2;
$divideColor-red-200: #fecaca;
$divideColor-red-300: #fca5a5;
$divideColor-red-400: #f87171;
$divideColor-red-500: #ef4444;
$divideColor-red-600: #dc2626;
$divideColor-red-700: #b91c1c;
$divideColor-red-800: #991b1b;
$divideColor-red-900: #7f1d1d;
$divideColor-yellow-50: #fffbeb;
$divideColor-yellow-100: #fef3c7;
$divideColor-yellow-200: #fde68a;
$divideColor-yellow-300: #fcd34d;
$divideColor-yellow-400: #fbbf24;
$divideColor-yellow-500: #f59e0b;
$divideColor-yellow-600: #d97706;
$divideColor-yellow-700: #b45309;
$divideColor-yellow-800: #92400e;
$divideColor-yellow-900: #78350f;
$divideColor-green-50: #ecfdf5;
$divideColor-green-100: #d1fae5;
$divideColor-green-200: #a7f3d0;
$divideColor-green-300: #6ee7b7;
$divideColor-green-400: #34d399;
$divideColor-green-500: #10b981;
$divideColor-green-600: #059669;
$divideColor-green-700: #047857;
$divideColor-green-800: #065f46;
$divideColor-green-900: #064e3b;
$divideColor-blue-50: #eff6ff;
$divideColor-blue-100: #dbeafe;
$divideColor-blue-200: #bfdbfe;
$divideColor-blue-300: #93c5fd;
$divideColor-blue-400: #60a5fa;
$divideColor-blue-500: #3b82f6;
$divideColor-blue-600: #2563eb;
$divideColor-blue-700: #1d4ed8;
$divideColor-blue-800: #1e40af;
$divideColor-blue-900: #1e3a8a;
$divideColor-indigo-50: #eef2ff;
$divideColor-indigo-100: #e0e7ff;
$divideColor-indigo-200: #c7d2fe;
$divideColor-indigo-300: #a5b4fc;
$divideColor-indigo-400: #818cf8;
$divideColor-indigo-500: #6366f1;
$divideColor-indigo-600: #4f46e5;
$divideColor-indigo-700: #4338ca;
$divideColor-indigo-800: #3730a3;
$divideColor-indigo-900: #312e81;
$divideColor-purple-50: #f5f3ff;
$divideColor-purple-100: #ede9fe;
$divideColor-purple-200: #ddd6fe;
$divideColor-purple-300: #c4b5fd;
$divideColor-purple-400: #a78bfa;
$divideColor-purple-500: #8b5cf6;
$divideColor-purple-600: #7c3aed;
$divideColor-purple-700: #6d28d9;
$divideColor-purple-800: #5b21b6;
$divideColor-purple-900: #4c1d95;
$divideColor-pink-50: #fdf2f8;
$divideColor-pink-100: #fce7f3;
$divideColor-pink-200: #fbcfe8;
$divideColor-pink-300: #f9a8d4;
$divideColor-pink-400: #f472b6;
$divideColor-pink-500: #ec4899;
$divideColor-pink-600: #db2777;
$divideColor-pink-700: #be185d;
$divideColor-pink-800: #9d174d;
$divideColor-pink-900: #831843;
$divideColor-DEFAULT: #e5e7eb;

$divideOpacity-0: 0;
$divideOpacity-5: 0.05;
$divideOpacity-10: 0.1;
$divideOpacity-20: 0.2;
$divideOpacity-25: 0.25;
$divideOpacity-30: 0.3;
$divideOpacity-40: 0.4;
$divideOpacity-50: 0.5;
$divideOpacity-60: 0.6;
$divideOpacity-70: 0.7;
$divideOpacity-75: 0.75;
$divideOpacity-80: 0.8;
$divideOpacity-90: 0.9;
$divideOpacity-95: 0.95;
$divideOpacity-100: 1;

$divideWidth-0: 0px;
$divideWidth-2: 2px;
$divideWidth-4: 4px;
$divideWidth-8: 8px;
$divideWidth-DEFAULT: 1px;

$fill-current: currentColor;

$flex-1: 1 1 0%;
$flex-auto: 1 1 auto;
$flex-initial: 0 1 auto;
$flex-none: none;

$flexGrow-0: 0;
$flexGrow-DEFAULT: 1;

$flexShrink-0: 0;
$flexShrink-DEFAULT: 1;

$fontFamily-sans: (ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji");
$fontFamily-serif: (ui-serif,Georgia,Cambria,"Times New Roman",Times,serif);
$fontFamily-mono: (ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace);

$fontSize-xs: 0.75rem;
$fontSize-xs-lineHeight: 1rem;
$fontSize-sm: 0.875rem;
$fontSize-sm-lineHeight: 1.25rem;
$fontSize-base: 1rem;
$fontSize-base-lineHeight: 1.5rem;
$fontSize-lg: 1.125rem;
$fontSize-lg-lineHeight: 1.75rem;
$fontSize-xl: 1.25rem;
$fontSize-xl-lineHeight: 1.75rem;
$fontSize-2xl: 1.5rem;
$fontSize-2xl-lineHeight: 2rem;
$fontSize-3xl: 1.875rem;
$fontSize-3xl-lineHeight: 2.25rem;
$fontSize-4xl: 2.25rem;
$fontSize-4xl-lineHeight: 2.5rem;
$fontSize-5xl: 3rem;
$fontSize-5xl-lineHeight: 1;
$fontSize-6xl: 3.75rem;
$fontSize-6xl-lineHeight: 1;
$fontSize-7xl: 4.5rem;
$fontSize-7xl-lineHeight: 1;
$fontSize-8xl: 6rem;
$fontSize-8xl-lineHeight: 1;
$fontSize-9xl: 8rem;
$fontSize-9xl-lineHeight: 1;

$fontWeight-thin: 100;
$fontWeight-extralight: 200;
$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;
$fontWeight-black: 900;

$gap-0: 0px;
$gap-1: 0.25rem;
$gap-2: 0.5rem;
$gap-3: 0.75rem;
$gap-4: 1rem;
$gap-5: 1.25rem;
$gap-6: 1.5rem;
$gap-7: 1.75rem;
$gap-8: 2rem;
$gap-9: 2.25rem;
$gap-10: 2.5rem;
$gap-11: 2.75rem;
$gap-12: 3rem;
$gap-14: 3.5rem;
$gap-16: 4rem;
$gap-20: 5rem;
$gap-24: 6rem;
$gap-28: 7rem;
$gap-32: 8rem;
$gap-36: 9rem;
$gap-40: 10rem;
$gap-44: 11rem;
$gap-48: 12rem;
$gap-52: 13rem;
$gap-56: 14rem;
$gap-60: 15rem;
$gap-64: 16rem;
$gap-72: 18rem;
$gap-80: 20rem;
$gap-96: 24rem;
$gap-px: 1px;
$gap-0-5: 0.125rem;
$gap-1-5: 0.375rem;
$gap-2-5: 0.625rem;
$gap-3-5: 0.875rem;
$gap-88px: 5.5rem;
$gap-284px: 17.75rem;
$gap-100px: 6.25rem;

$gradientColorStops-transparent: transparent;
$gradientColorStops-current: currentColor;
$gradientColorStops-primary: #ea580c;
$gradientColorStops-danger: #DC2626;
$gradientColorStops-black: #000;
$gradientColorStops-orange-50: #fff7ed;
$gradientColorStops-orange-100: #ffedd5;
$gradientColorStops-orange-200: #fed7aa;
$gradientColorStops-orange-300: #fdba74;
$gradientColorStops-orange-400: #fb923c;
$gradientColorStops-orange-500: #f97316;
$gradientColorStops-orange-600: #ea580c;
$gradientColorStops-orange-700: #c2410c;
$gradientColorStops-orange-800: #9a3412;
$gradientColorStops-orange-900: #7c2d12;
$gradientColorStops-white: #fff;
$gradientColorStops-gray-50: #f9fafb;
$gradientColorStops-gray-100: #f3f4f6;
$gradientColorStops-gray-200: #e5e7eb;
$gradientColorStops-gray-300: #d1d5db;
$gradientColorStops-gray-400: #9ca3af;
$gradientColorStops-gray-500: #6b7280;
$gradientColorStops-gray-600: #4b5563;
$gradientColorStops-gray-700: #374151;
$gradientColorStops-gray-800: #1f2937;
$gradientColorStops-gray-900: #111827;
$gradientColorStops-red-50: #fef2f2;
$gradientColorStops-red-100: #fee2e2;
$gradientColorStops-red-200: #fecaca;
$gradientColorStops-red-300: #fca5a5;
$gradientColorStops-red-400: #f87171;
$gradientColorStops-red-500: #ef4444;
$gradientColorStops-red-600: #dc2626;
$gradientColorStops-red-700: #b91c1c;
$gradientColorStops-red-800: #991b1b;
$gradientColorStops-red-900: #7f1d1d;
$gradientColorStops-yellow-50: #fffbeb;
$gradientColorStops-yellow-100: #fef3c7;
$gradientColorStops-yellow-200: #fde68a;
$gradientColorStops-yellow-300: #fcd34d;
$gradientColorStops-yellow-400: #fbbf24;
$gradientColorStops-yellow-500: #f59e0b;
$gradientColorStops-yellow-600: #d97706;
$gradientColorStops-yellow-700: #b45309;
$gradientColorStops-yellow-800: #92400e;
$gradientColorStops-yellow-900: #78350f;
$gradientColorStops-green-50: #ecfdf5;
$gradientColorStops-green-100: #d1fae5;
$gradientColorStops-green-200: #a7f3d0;
$gradientColorStops-green-300: #6ee7b7;
$gradientColorStops-green-400: #34d399;
$gradientColorStops-green-500: #10b981;
$gradientColorStops-green-600: #059669;
$gradientColorStops-green-700: #047857;
$gradientColorStops-green-800: #065f46;
$gradientColorStops-green-900: #064e3b;
$gradientColorStops-blue-50: #eff6ff;
$gradientColorStops-blue-100: #dbeafe;
$gradientColorStops-blue-200: #bfdbfe;
$gradientColorStops-blue-300: #93c5fd;
$gradientColorStops-blue-400: #60a5fa;
$gradientColorStops-blue-500: #3b82f6;
$gradientColorStops-blue-600: #2563eb;
$gradientColorStops-blue-700: #1d4ed8;
$gradientColorStops-blue-800: #1e40af;
$gradientColorStops-blue-900: #1e3a8a;
$gradientColorStops-indigo-50: #eef2ff;
$gradientColorStops-indigo-100: #e0e7ff;
$gradientColorStops-indigo-200: #c7d2fe;
$gradientColorStops-indigo-300: #a5b4fc;
$gradientColorStops-indigo-400: #818cf8;
$gradientColorStops-indigo-500: #6366f1;
$gradientColorStops-indigo-600: #4f46e5;
$gradientColorStops-indigo-700: #4338ca;
$gradientColorStops-indigo-800: #3730a3;
$gradientColorStops-indigo-900: #312e81;
$gradientColorStops-purple-50: #f5f3ff;
$gradientColorStops-purple-100: #ede9fe;
$gradientColorStops-purple-200: #ddd6fe;
$gradientColorStops-purple-300: #c4b5fd;
$gradientColorStops-purple-400: #a78bfa;
$gradientColorStops-purple-500: #8b5cf6;
$gradientColorStops-purple-600: #7c3aed;
$gradientColorStops-purple-700: #6d28d9;
$gradientColorStops-purple-800: #5b21b6;
$gradientColorStops-purple-900: #4c1d95;
$gradientColorStops-pink-50: #fdf2f8;
$gradientColorStops-pink-100: #fce7f3;
$gradientColorStops-pink-200: #fbcfe8;
$gradientColorStops-pink-300: #f9a8d4;
$gradientColorStops-pink-400: #f472b6;
$gradientColorStops-pink-500: #ec4899;
$gradientColorStops-pink-600: #db2777;
$gradientColorStops-pink-700: #be185d;
$gradientColorStops-pink-800: #9d174d;
$gradientColorStops-pink-900: #831843;

$gridAutoColumns-auto: auto;
$gridAutoColumns-min: min-content;
$gridAutoColumns-max: max-content;
$gridAutoColumns-fr: (minmax(0, 1fr));

$gridAutoRows-auto: auto;
$gridAutoRows-min: min-content;
$gridAutoRows-max: max-content;
$gridAutoRows-fr: (minmax(0, 1fr));

$gridColumn-auto: auto;
$gridColumn-span-1: span 1 / span 1;
$gridColumn-span-2: span 2 / span 2;
$gridColumn-span-3: span 3 / span 3;
$gridColumn-span-4: span 4 / span 4;
$gridColumn-span-5: span 5 / span 5;
$gridColumn-span-6: span 6 / span 6;
$gridColumn-span-7: span 7 / span 7;
$gridColumn-span-8: span 8 / span 8;
$gridColumn-span-9: span 9 / span 9;
$gridColumn-span-10: span 10 / span 10;
$gridColumn-span-11: span 11 / span 11;
$gridColumn-span-12: span 12 / span 12;
$gridColumn-span-full: 1 / -1;

$gridColumnEnd-1: 1;
$gridColumnEnd-2: 2;
$gridColumnEnd-3: 3;
$gridColumnEnd-4: 4;
$gridColumnEnd-5: 5;
$gridColumnEnd-6: 6;
$gridColumnEnd-7: 7;
$gridColumnEnd-8: 8;
$gridColumnEnd-9: 9;
$gridColumnEnd-10: 10;
$gridColumnEnd-11: 11;
$gridColumnEnd-12: 12;
$gridColumnEnd-13: 13;
$gridColumnEnd-auto: auto;

$gridColumnStart-1: 1;
$gridColumnStart-2: 2;
$gridColumnStart-3: 3;
$gridColumnStart-4: 4;
$gridColumnStart-5: 5;
$gridColumnStart-6: 6;
$gridColumnStart-7: 7;
$gridColumnStart-8: 8;
$gridColumnStart-9: 9;
$gridColumnStart-10: 10;
$gridColumnStart-11: 11;
$gridColumnStart-12: 12;
$gridColumnStart-13: 13;
$gridColumnStart-auto: auto;

$gridRow-auto: auto;
$gridRow-span-1: span 1 / span 1;
$gridRow-span-2: span 2 / span 2;
$gridRow-span-3: span 3 / span 3;
$gridRow-span-4: span 4 / span 4;
$gridRow-span-5: span 5 / span 5;
$gridRow-span-6: span 6 / span 6;
$gridRow-span-full: 1 / -1;

$gridRowStart-1: 1;
$gridRowStart-2: 2;
$gridRowStart-3: 3;
$gridRowStart-4: 4;
$gridRowStart-5: 5;
$gridRowStart-6: 6;
$gridRowStart-7: 7;
$gridRowStart-auto: auto;

$gridRowEnd-1: 1;
$gridRowEnd-2: 2;
$gridRowEnd-3: 3;
$gridRowEnd-4: 4;
$gridRowEnd-5: 5;
$gridRowEnd-6: 6;
$gridRowEnd-7: 7;
$gridRowEnd-auto: auto;

$transformOrigin-center: center;
$transformOrigin-top: top;
$transformOrigin-top-right: top right;
$transformOrigin-right: right;
$transformOrigin-bottom-right: bottom right;
$transformOrigin-bottom: bottom;
$transformOrigin-bottom-left: bottom left;
$transformOrigin-left: left;
$transformOrigin-top-left: top left;

$gridTemplateColumns-1: (repeat(1, minmax(0, 1fr)));
$gridTemplateColumns-2: (repeat(2, minmax(0, 1fr)));
$gridTemplateColumns-3: (repeat(3, minmax(0, 1fr)));
$gridTemplateColumns-4: (repeat(4, minmax(0, 1fr)));
$gridTemplateColumns-5: (repeat(5, minmax(0, 1fr)));
$gridTemplateColumns-6: (repeat(6, minmax(0, 1fr)));
$gridTemplateColumns-7: (repeat(7, minmax(0, 1fr)));
$gridTemplateColumns-8: (repeat(8, minmax(0, 1fr)));
$gridTemplateColumns-9: (repeat(9, minmax(0, 1fr)));
$gridTemplateColumns-10: (repeat(10, minmax(0, 1fr)));
$gridTemplateColumns-11: (repeat(11, minmax(0, 1fr)));
$gridTemplateColumns-12: (repeat(12, minmax(0, 1fr)));
$gridTemplateColumns-none: none;

$gridTemplateRows-1: (repeat(1, minmax(0, 1fr)));
$gridTemplateRows-2: (repeat(2, minmax(0, 1fr)));
$gridTemplateRows-3: (repeat(3, minmax(0, 1fr)));
$gridTemplateRows-4: (repeat(4, minmax(0, 1fr)));
$gridTemplateRows-5: (repeat(5, minmax(0, 1fr)));
$gridTemplateRows-6: (repeat(6, minmax(0, 1fr)));
$gridTemplateRows-none: none;

$height-0: 0px;
$height-1: 0.25rem;
$height-2: 0.5rem;
$height-3: 0.75rem;
$height-4: 1rem;
$height-5: 1.25rem;
$height-6: 1.5rem;
$height-7: 1.75rem;
$height-8: 2rem;
$height-9: 2.25rem;
$height-10: 2.5rem;
$height-11: 2.75rem;
$height-12: 3rem;
$height-14: 3.5rem;
$height-16: 4rem;
$height-20: 5rem;
$height-24: 6rem;
$height-28: 7rem;
$height-32: 8rem;
$height-36: 9rem;
$height-40: 10rem;
$height-44: 11rem;
$height-48: 12rem;
$height-52: 13rem;
$height-56: 14rem;
$height-60: 15rem;
$height-64: 16rem;
$height-72: 18rem;
$height-80: 20rem;
$height-96: 24rem;
$height-auto: auto;
$height-px: 1px;
$height-0-5: 0.125rem;
$height-1-5: 0.375rem;
$height-2-5: 0.625rem;
$height-3-5: 0.875rem;
$height-88px: 5.5rem;
$height-284px: 17.75rem;
$height-100px: 6.25rem;
$height-1\/2: 50%;
$height-1\/3: 33.333333%;
$height-2\/3: 66.666667%;
$height-1\/4: 25%;
$height-2\/4: 50%;
$height-3\/4: 75%;
$height-1\/5: 20%;
$height-2\/5: 40%;
$height-3\/5: 60%;
$height-4\/5: 80%;
$height-1\/6: 16.666667%;
$height-2\/6: 33.333333%;
$height-3\/6: 50%;
$height-4\/6: 66.666667%;
$height-5\/6: 83.333333%;
$height-full: 100%;
$height-screen: 100vh;

$inset-0: 0px;
$inset-1: 0.25rem;
$inset-2: 0.5rem;
$inset-3: 0.75rem;
$inset-4: 1rem;
$inset-5: 1.25rem;
$inset-6: 1.5rem;
$inset-7: 1.75rem;
$inset-8: 2rem;
$inset-9: 2.25rem;
$inset-10: 2.5rem;
$inset-11: 2.75rem;
$inset-12: 3rem;
$inset-14: 3.5rem;
$inset-16: 4rem;
$inset-20: 5rem;
$inset-24: 6rem;
$inset-28: 7rem;
$inset-32: 8rem;
$inset-36: 9rem;
$inset-40: 10rem;
$inset-44: 11rem;
$inset-48: 12rem;
$inset-52: 13rem;
$inset-56: 14rem;
$inset-60: 15rem;
$inset-64: 16rem;
$inset-72: 18rem;
$inset-80: 20rem;
$inset-96: 24rem;
$inset-auto: auto;
$inset-px: 1px;
$inset-0-5: 0.125rem;
$inset-1-5: 0.375rem;
$inset-2-5: 0.625rem;
$inset-3-5: 0.875rem;
$inset-88px: 5.5rem;
$inset-284px: 17.75rem;
$inset-100px: 6.25rem;
$inset--0: 0px;
$inset--1: -0.25rem;
$inset--2: -0.5rem;
$inset--3: -0.75rem;
$inset--4: -1rem;
$inset--5: -1.25rem;
$inset--6: -1.5rem;
$inset--7: -1.75rem;
$inset--8: -2rem;
$inset--9: -2.25rem;
$inset--10: -2.5rem;
$inset--11: -2.75rem;
$inset--12: -3rem;
$inset--14: -3.5rem;
$inset--16: -4rem;
$inset--20: -5rem;
$inset--24: -6rem;
$inset--28: -7rem;
$inset--32: -8rem;
$inset--36: -9rem;
$inset--40: -10rem;
$inset--44: -11rem;
$inset--48: -12rem;
$inset--52: -13rem;
$inset--56: -14rem;
$inset--60: -15rem;
$inset--64: -16rem;
$inset--72: -18rem;
$inset--80: -20rem;
$inset--96: -24rem;
$inset--px: -1px;
$inset--0-5: -0.125rem;
$inset--1-5: -0.375rem;
$inset--2-5: -0.625rem;
$inset--3-5: -0.875rem;
$inset--88px: -5.5rem;
$inset--284px: -17.75rem;
$inset--100px: -6.25rem;
$inset-1\/2: 50%;
$inset-1\/3: 33.333333%;
$inset-2\/3: 66.666667%;
$inset-1\/4: 25%;
$inset-2\/4: 50%;
$inset-3\/4: 75%;
$inset-full: 100%;
$inset--1\/2: -50%;
$inset--1\/3: -33.333333%;
$inset--2\/3: -66.666667%;
$inset--1\/4: -25%;
$inset--2\/4: -50%;
$inset--3\/4: -75%;
$inset--full: -100%;

$letterSpacing-tighter: -0.05em;
$letterSpacing-tight: -0.025em;
$letterSpacing-normal: 0em;
$letterSpacing-wide: 0.025em;
$letterSpacing-wider: 0.05em;
$letterSpacing-widest: 0.1em;

$lineHeight-3: .75rem;
$lineHeight-4: 1rem;
$lineHeight-5: 1.25rem;
$lineHeight-6: 1.5rem;
$lineHeight-7: 1.75rem;
$lineHeight-8: 2rem;
$lineHeight-9: 2.25rem;
$lineHeight-10: 2.5rem;
$lineHeight-none: 1;
$lineHeight-tight: 1.25;
$lineHeight-snug: 1.375;
$lineHeight-normal: 1.5;
$lineHeight-relaxed: 1.625;
$lineHeight-loose: 2;

$listStyleType-none: none;
$listStyleType-disc: disc;
$listStyleType-decimal: decimal;

$margin-0: 0px;
$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 0.75rem;
$margin-4: 1rem;
$margin-5: 1.25rem;
$margin-6: 1.5rem;
$margin-7: 1.75rem;
$margin-8: 2rem;
$margin-9: 2.25rem;
$margin-10: 2.5rem;
$margin-11: 2.75rem;
$margin-12: 3rem;
$margin-14: 3.5rem;
$margin-16: 4rem;
$margin-20: 5rem;
$margin-24: 6rem;
$margin-28: 7rem;
$margin-32: 8rem;
$margin-36: 9rem;
$margin-40: 10rem;
$margin-44: 11rem;
$margin-48: 12rem;
$margin-52: 13rem;
$margin-56: 14rem;
$margin-60: 15rem;
$margin-64: 16rem;
$margin-72: 18rem;
$margin-80: 20rem;
$margin-96: 24rem;
$margin-auto: auto;
$margin-px: 1px;
$margin-0-5: 0.125rem;
$margin-1-5: 0.375rem;
$margin-2-5: 0.625rem;
$margin-3-5: 0.875rem;
$margin-88px: 5.5rem;
$margin-284px: 17.75rem;
$margin-100px: 6.25rem;
$margin--0: 0px;
$margin--1: -0.25rem;
$margin--2: -0.5rem;
$margin--3: -0.75rem;
$margin--4: -1rem;
$margin--5: -1.25rem;
$margin--6: -1.5rem;
$margin--7: -1.75rem;
$margin--8: -2rem;
$margin--9: -2.25rem;
$margin--10: -2.5rem;
$margin--11: -2.75rem;
$margin--12: -3rem;
$margin--14: -3.5rem;
$margin--16: -4rem;
$margin--20: -5rem;
$margin--24: -6rem;
$margin--28: -7rem;
$margin--32: -8rem;
$margin--36: -9rem;
$margin--40: -10rem;
$margin--44: -11rem;
$margin--48: -12rem;
$margin--52: -13rem;
$margin--56: -14rem;
$margin--60: -15rem;
$margin--64: -16rem;
$margin--72: -18rem;
$margin--80: -20rem;
$margin--96: -24rem;
$margin--px: -1px;
$margin--0-5: -0.125rem;
$margin--1-5: -0.375rem;
$margin--2-5: -0.625rem;
$margin--3-5: -0.875rem;
$margin--88px: -5.5rem;
$margin--284px: -17.75rem;
$margin--100px: -6.25rem;

$maxHeight-0: 0px;
$maxHeight-1: 0.25rem;
$maxHeight-2: 0.5rem;
$maxHeight-3: 0.75rem;
$maxHeight-4: 1rem;
$maxHeight-5: 1.25rem;
$maxHeight-6: 1.5rem;
$maxHeight-7: 1.75rem;
$maxHeight-8: 2rem;
$maxHeight-9: 2.25rem;
$maxHeight-10: 2.5rem;
$maxHeight-11: 2.75rem;
$maxHeight-12: 3rem;
$maxHeight-14: 3.5rem;
$maxHeight-16: 4rem;
$maxHeight-20: 5rem;
$maxHeight-24: 6rem;
$maxHeight-28: 7rem;
$maxHeight-32: 8rem;
$maxHeight-36: 9rem;
$maxHeight-40: 10rem;
$maxHeight-44: 11rem;
$maxHeight-48: 12rem;
$maxHeight-52: 13rem;
$maxHeight-56: 14rem;
$maxHeight-60: 15rem;
$maxHeight-64: 16rem;
$maxHeight-72: 18rem;
$maxHeight-80: 20rem;
$maxHeight-96: 24rem;
$maxHeight-px: 1px;
$maxHeight-0-5: 0.125rem;
$maxHeight-1-5: 0.375rem;
$maxHeight-2-5: 0.625rem;
$maxHeight-3-5: 0.875rem;
$maxHeight-88px: 5.5rem;
$maxHeight-284px: 17.75rem;
$maxHeight-100px: 6.25rem;
$maxHeight-full: 100%;
$maxHeight-screen: 100vh;

$maxWidth-0: 0px;
$maxWidth-1: 0.25rem;
$maxWidth-2: 0.5rem;
$maxWidth-3: 0.75rem;
$maxWidth-4: 1rem;
$maxWidth-5: 1.25rem;
$maxWidth-6: 1.5rem;
$maxWidth-7: 1.75rem;
$maxWidth-8: 2rem;
$maxWidth-9: 2.25rem;
$maxWidth-10: 2.5rem;
$maxWidth-11: 2.75rem;
$maxWidth-12: 3rem;
$maxWidth-14: 3.5rem;
$maxWidth-16: 4rem;
$maxWidth-20: 5rem;
$maxWidth-24: 6rem;
$maxWidth-28: 7rem;
$maxWidth-32: 8rem;
$maxWidth-36: 9rem;
$maxWidth-40: 10rem;
$maxWidth-44: 11rem;
$maxWidth-48: 12rem;
$maxWidth-52: 13rem;
$maxWidth-56: 14rem;
$maxWidth-60: 15rem;
$maxWidth-64: 16rem;
$maxWidth-72: 18rem;
$maxWidth-80: 20rem;
$maxWidth-96: 24rem;
$maxWidth-none: none;
$maxWidth-xs: 20rem;
$maxWidth-sm: 24rem;
$maxWidth-md: 28rem;
$maxWidth-lg: 32rem;
$maxWidth-xl: 36rem;
$maxWidth-2xl: 42rem;
$maxWidth-3xl: 48rem;
$maxWidth-4xl: 56rem;
$maxWidth-5xl: 64rem;
$maxWidth-6xl: 72rem;
$maxWidth-7xl: 80rem;
$maxWidth-full: 100%;
$maxWidth-min: min-content;
$maxWidth-max: max-content;
$maxWidth-prose: 65ch;
$maxWidth-screen-sm: 640px;
$maxWidth-screen-md: 768px;
$maxWidth-screen-lg: 1024px;
$maxWidth-screen-xl: 1280px;
$maxWidth-screen-2xl: 1536px;
$maxWidth-px: 1px;
$maxWidth-0-5: 0.125rem;
$maxWidth-1-5: 0.375rem;
$maxWidth-2-5: 0.625rem;
$maxWidth-3-5: 0.875rem;
$maxWidth-88px: 5.5rem;
$maxWidth-284px: 17.75rem;
$maxWidth-100px: 6.25rem;

$minHeight-0: 0px;
$minHeight-full: 100%;
$minHeight-screen: 100vh;

$minWidth-0: 0px;
$minWidth-full: 100%;
$minWidth-min: min-content;
$minWidth-max: max-content;

$objectPosition-bottom: bottom;
$objectPosition-center: center;
$objectPosition-left: left;
$objectPosition-left-bottom: left bottom;
$objectPosition-left-top: left top;
$objectPosition-right: right;
$objectPosition-right-bottom: right bottom;
$objectPosition-right-top: right top;
$objectPosition-top: top;

$opacity-0: 0;
$opacity-5: 0.05;
$opacity-10: 0.1;
$opacity-20: 0.2;
$opacity-25: 0.25;
$opacity-30: 0.3;
$opacity-40: 0.4;
$opacity-50: 0.5;
$opacity-60: 0.6;
$opacity-70: 0.7;
$opacity-75: 0.75;
$opacity-80: 0.8;
$opacity-90: 0.9;
$opacity-95: 0.95;
$opacity-100: 1;

$order-1: 1;
$order-2: 2;
$order-3: 3;
$order-4: 4;
$order-5: 5;
$order-6: 6;
$order-7: 7;
$order-8: 8;
$order-9: 9;
$order-10: 10;
$order-11: 11;
$order-12: 12;
$order-first: -9999;
$order-last: 9999;
$order-none: 0;

$outline-none: (2px solid transparent,2px);
$outline-white: (2px dotted white,2px);
$outline-black: (2px dotted black,2px);

$padding-0: 0px;
$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 0.75rem;
$padding-4: 1rem;
$padding-5: 1.25rem;
$padding-6: 1.5rem;
$padding-7: 1.75rem;
$padding-8: 2rem;
$padding-9: 2.25rem;
$padding-10: 2.5rem;
$padding-11: 2.75rem;
$padding-12: 3rem;
$padding-14: 3.5rem;
$padding-16: 4rem;
$padding-20: 5rem;
$padding-24: 6rem;
$padding-28: 7rem;
$padding-32: 8rem;
$padding-36: 9rem;
$padding-40: 10rem;
$padding-44: 11rem;
$padding-48: 12rem;
$padding-52: 13rem;
$padding-56: 14rem;
$padding-60: 15rem;
$padding-64: 16rem;
$padding-72: 18rem;
$padding-80: 20rem;
$padding-96: 24rem;
$padding-px: 1px;
$padding-0-5: 0.125rem;
$padding-1-5: 0.375rem;
$padding-2-5: 0.625rem;
$padding-3-5: 0.875rem;
$padding-88px: 5.5rem;
$padding-284px: 17.75rem;
$padding-100px: 6.25rem;

$placeholderColor-transparent: transparent;
$placeholderColor-current: currentColor;
$placeholderColor-primary: #ea580c;
$placeholderColor-danger: #DC2626;
$placeholderColor-black: #000;
$placeholderColor-orange-50: #fff7ed;
$placeholderColor-orange-100: #ffedd5;
$placeholderColor-orange-200: #fed7aa;
$placeholderColor-orange-300: #fdba74;
$placeholderColor-orange-400: #fb923c;
$placeholderColor-orange-500: #f97316;
$placeholderColor-orange-600: #ea580c;
$placeholderColor-orange-700: #c2410c;
$placeholderColor-orange-800: #9a3412;
$placeholderColor-orange-900: #7c2d12;
$placeholderColor-white: #fff;
$placeholderColor-gray-50: #f9fafb;
$placeholderColor-gray-100: #f3f4f6;
$placeholderColor-gray-200: #e5e7eb;
$placeholderColor-gray-300: #d1d5db;
$placeholderColor-gray-400: #9ca3af;
$placeholderColor-gray-500: #6b7280;
$placeholderColor-gray-600: #4b5563;
$placeholderColor-gray-700: #374151;
$placeholderColor-gray-800: #1f2937;
$placeholderColor-gray-900: #111827;
$placeholderColor-red-50: #fef2f2;
$placeholderColor-red-100: #fee2e2;
$placeholderColor-red-200: #fecaca;
$placeholderColor-red-300: #fca5a5;
$placeholderColor-red-400: #f87171;
$placeholderColor-red-500: #ef4444;
$placeholderColor-red-600: #dc2626;
$placeholderColor-red-700: #b91c1c;
$placeholderColor-red-800: #991b1b;
$placeholderColor-red-900: #7f1d1d;
$placeholderColor-yellow-50: #fffbeb;
$placeholderColor-yellow-100: #fef3c7;
$placeholderColor-yellow-200: #fde68a;
$placeholderColor-yellow-300: #fcd34d;
$placeholderColor-yellow-400: #fbbf24;
$placeholderColor-yellow-500: #f59e0b;
$placeholderColor-yellow-600: #d97706;
$placeholderColor-yellow-700: #b45309;
$placeholderColor-yellow-800: #92400e;
$placeholderColor-yellow-900: #78350f;
$placeholderColor-green-50: #ecfdf5;
$placeholderColor-green-100: #d1fae5;
$placeholderColor-green-200: #a7f3d0;
$placeholderColor-green-300: #6ee7b7;
$placeholderColor-green-400: #34d399;
$placeholderColor-green-500: #10b981;
$placeholderColor-green-600: #059669;
$placeholderColor-green-700: #047857;
$placeholderColor-green-800: #065f46;
$placeholderColor-green-900: #064e3b;
$placeholderColor-blue-50: #eff6ff;
$placeholderColor-blue-100: #dbeafe;
$placeholderColor-blue-200: #bfdbfe;
$placeholderColor-blue-300: #93c5fd;
$placeholderColor-blue-400: #60a5fa;
$placeholderColor-blue-500: #3b82f6;
$placeholderColor-blue-600: #2563eb;
$placeholderColor-blue-700: #1d4ed8;
$placeholderColor-blue-800: #1e40af;
$placeholderColor-blue-900: #1e3a8a;
$placeholderColor-indigo-50: #eef2ff;
$placeholderColor-indigo-100: #e0e7ff;
$placeholderColor-indigo-200: #c7d2fe;
$placeholderColor-indigo-300: #a5b4fc;
$placeholderColor-indigo-400: #818cf8;
$placeholderColor-indigo-500: #6366f1;
$placeholderColor-indigo-600: #4f46e5;
$placeholderColor-indigo-700: #4338ca;
$placeholderColor-indigo-800: #3730a3;
$placeholderColor-indigo-900: #312e81;
$placeholderColor-purple-50: #f5f3ff;
$placeholderColor-purple-100: #ede9fe;
$placeholderColor-purple-200: #ddd6fe;
$placeholderColor-purple-300: #c4b5fd;
$placeholderColor-purple-400: #a78bfa;
$placeholderColor-purple-500: #8b5cf6;
$placeholderColor-purple-600: #7c3aed;
$placeholderColor-purple-700: #6d28d9;
$placeholderColor-purple-800: #5b21b6;
$placeholderColor-purple-900: #4c1d95;
$placeholderColor-pink-50: #fdf2f8;
$placeholderColor-pink-100: #fce7f3;
$placeholderColor-pink-200: #fbcfe8;
$placeholderColor-pink-300: #f9a8d4;
$placeholderColor-pink-400: #f472b6;
$placeholderColor-pink-500: #ec4899;
$placeholderColor-pink-600: #db2777;
$placeholderColor-pink-700: #be185d;
$placeholderColor-pink-800: #9d174d;
$placeholderColor-pink-900: #831843;

$placeholderOpacity-0: 0;
$placeholderOpacity-5: 0.05;
$placeholderOpacity-10: 0.1;
$placeholderOpacity-20: 0.2;
$placeholderOpacity-25: 0.25;
$placeholderOpacity-30: 0.3;
$placeholderOpacity-40: 0.4;
$placeholderOpacity-50: 0.5;
$placeholderOpacity-60: 0.6;
$placeholderOpacity-70: 0.7;
$placeholderOpacity-75: 0.75;
$placeholderOpacity-80: 0.8;
$placeholderOpacity-90: 0.9;
$placeholderOpacity-95: 0.95;
$placeholderOpacity-100: 1;

$ringColor-DEFAULT: #ea580c;
$ringColor-transparent: transparent;
$ringColor-current: currentColor;
$ringColor-primary: #ea580c;
$ringColor-danger: #DC2626;
$ringColor-black: #000;
$ringColor-orange-50: #fff7ed;
$ringColor-orange-100: #ffedd5;
$ringColor-orange-200: #fed7aa;
$ringColor-orange-300: #fdba74;
$ringColor-orange-400: #fb923c;
$ringColor-orange-500: #f97316;
$ringColor-orange-600: #ea580c;
$ringColor-orange-700: #c2410c;
$ringColor-orange-800: #9a3412;
$ringColor-orange-900: #7c2d12;
$ringColor-white: #fff;
$ringColor-gray-50: #f9fafb;
$ringColor-gray-100: #f3f4f6;
$ringColor-gray-200: #e5e7eb;
$ringColor-gray-300: #d1d5db;
$ringColor-gray-400: #9ca3af;
$ringColor-gray-500: #6b7280;
$ringColor-gray-600: #4b5563;
$ringColor-gray-700: #374151;
$ringColor-gray-800: #1f2937;
$ringColor-gray-900: #111827;
$ringColor-red-50: #fef2f2;
$ringColor-red-100: #fee2e2;
$ringColor-red-200: #fecaca;
$ringColor-red-300: #fca5a5;
$ringColor-red-400: #f87171;
$ringColor-red-500: #ef4444;
$ringColor-red-600: #dc2626;
$ringColor-red-700: #b91c1c;
$ringColor-red-800: #991b1b;
$ringColor-red-900: #7f1d1d;
$ringColor-yellow-50: #fffbeb;
$ringColor-yellow-100: #fef3c7;
$ringColor-yellow-200: #fde68a;
$ringColor-yellow-300: #fcd34d;
$ringColor-yellow-400: #fbbf24;
$ringColor-yellow-500: #f59e0b;
$ringColor-yellow-600: #d97706;
$ringColor-yellow-700: #b45309;
$ringColor-yellow-800: #92400e;
$ringColor-yellow-900: #78350f;
$ringColor-green-50: #ecfdf5;
$ringColor-green-100: #d1fae5;
$ringColor-green-200: #a7f3d0;
$ringColor-green-300: #6ee7b7;
$ringColor-green-400: #34d399;
$ringColor-green-500: #10b981;
$ringColor-green-600: #059669;
$ringColor-green-700: #047857;
$ringColor-green-800: #065f46;
$ringColor-green-900: #064e3b;
$ringColor-blue-50: #eff6ff;
$ringColor-blue-100: #dbeafe;
$ringColor-blue-200: #bfdbfe;
$ringColor-blue-300: #93c5fd;
$ringColor-blue-400: #60a5fa;
$ringColor-blue-500: #3b82f6;
$ringColor-blue-600: #2563eb;
$ringColor-blue-700: #1d4ed8;
$ringColor-blue-800: #1e40af;
$ringColor-blue-900: #1e3a8a;
$ringColor-indigo-50: #eef2ff;
$ringColor-indigo-100: #e0e7ff;
$ringColor-indigo-200: #c7d2fe;
$ringColor-indigo-300: #a5b4fc;
$ringColor-indigo-400: #818cf8;
$ringColor-indigo-500: #6366f1;
$ringColor-indigo-600: #4f46e5;
$ringColor-indigo-700: #4338ca;
$ringColor-indigo-800: #3730a3;
$ringColor-indigo-900: #312e81;
$ringColor-purple-50: #f5f3ff;
$ringColor-purple-100: #ede9fe;
$ringColor-purple-200: #ddd6fe;
$ringColor-purple-300: #c4b5fd;
$ringColor-purple-400: #a78bfa;
$ringColor-purple-500: #8b5cf6;
$ringColor-purple-600: #7c3aed;
$ringColor-purple-700: #6d28d9;
$ringColor-purple-800: #5b21b6;
$ringColor-purple-900: #4c1d95;
$ringColor-pink-50: #fdf2f8;
$ringColor-pink-100: #fce7f3;
$ringColor-pink-200: #fbcfe8;
$ringColor-pink-300: #f9a8d4;
$ringColor-pink-400: #f472b6;
$ringColor-pink-500: #ec4899;
$ringColor-pink-600: #db2777;
$ringColor-pink-700: #be185d;
$ringColor-pink-800: #9d174d;
$ringColor-pink-900: #831843;

$ringOffsetColor-transparent: transparent;
$ringOffsetColor-current: currentColor;
$ringOffsetColor-primary: #ea580c;
$ringOffsetColor-danger: #DC2626;
$ringOffsetColor-black: #000;
$ringOffsetColor-orange-50: #fff7ed;
$ringOffsetColor-orange-100: #ffedd5;
$ringOffsetColor-orange-200: #fed7aa;
$ringOffsetColor-orange-300: #fdba74;
$ringOffsetColor-orange-400: #fb923c;
$ringOffsetColor-orange-500: #f97316;
$ringOffsetColor-orange-600: #ea580c;
$ringOffsetColor-orange-700: #c2410c;
$ringOffsetColor-orange-800: #9a3412;
$ringOffsetColor-orange-900: #7c2d12;
$ringOffsetColor-white: #fff;
$ringOffsetColor-gray-50: #f9fafb;
$ringOffsetColor-gray-100: #f3f4f6;
$ringOffsetColor-gray-200: #e5e7eb;
$ringOffsetColor-gray-300: #d1d5db;
$ringOffsetColor-gray-400: #9ca3af;
$ringOffsetColor-gray-500: #6b7280;
$ringOffsetColor-gray-600: #4b5563;
$ringOffsetColor-gray-700: #374151;
$ringOffsetColor-gray-800: #1f2937;
$ringOffsetColor-gray-900: #111827;
$ringOffsetColor-red-50: #fef2f2;
$ringOffsetColor-red-100: #fee2e2;
$ringOffsetColor-red-200: #fecaca;
$ringOffsetColor-red-300: #fca5a5;
$ringOffsetColor-red-400: #f87171;
$ringOffsetColor-red-500: #ef4444;
$ringOffsetColor-red-600: #dc2626;
$ringOffsetColor-red-700: #b91c1c;
$ringOffsetColor-red-800: #991b1b;
$ringOffsetColor-red-900: #7f1d1d;
$ringOffsetColor-yellow-50: #fffbeb;
$ringOffsetColor-yellow-100: #fef3c7;
$ringOffsetColor-yellow-200: #fde68a;
$ringOffsetColor-yellow-300: #fcd34d;
$ringOffsetColor-yellow-400: #fbbf24;
$ringOffsetColor-yellow-500: #f59e0b;
$ringOffsetColor-yellow-600: #d97706;
$ringOffsetColor-yellow-700: #b45309;
$ringOffsetColor-yellow-800: #92400e;
$ringOffsetColor-yellow-900: #78350f;
$ringOffsetColor-green-50: #ecfdf5;
$ringOffsetColor-green-100: #d1fae5;
$ringOffsetColor-green-200: #a7f3d0;
$ringOffsetColor-green-300: #6ee7b7;
$ringOffsetColor-green-400: #34d399;
$ringOffsetColor-green-500: #10b981;
$ringOffsetColor-green-600: #059669;
$ringOffsetColor-green-700: #047857;
$ringOffsetColor-green-800: #065f46;
$ringOffsetColor-green-900: #064e3b;
$ringOffsetColor-blue-50: #eff6ff;
$ringOffsetColor-blue-100: #dbeafe;
$ringOffsetColor-blue-200: #bfdbfe;
$ringOffsetColor-blue-300: #93c5fd;
$ringOffsetColor-blue-400: #60a5fa;
$ringOffsetColor-blue-500: #3b82f6;
$ringOffsetColor-blue-600: #2563eb;
$ringOffsetColor-blue-700: #1d4ed8;
$ringOffsetColor-blue-800: #1e40af;
$ringOffsetColor-blue-900: #1e3a8a;
$ringOffsetColor-indigo-50: #eef2ff;
$ringOffsetColor-indigo-100: #e0e7ff;
$ringOffsetColor-indigo-200: #c7d2fe;
$ringOffsetColor-indigo-300: #a5b4fc;
$ringOffsetColor-indigo-400: #818cf8;
$ringOffsetColor-indigo-500: #6366f1;
$ringOffsetColor-indigo-600: #4f46e5;
$ringOffsetColor-indigo-700: #4338ca;
$ringOffsetColor-indigo-800: #3730a3;
$ringOffsetColor-indigo-900: #312e81;
$ringOffsetColor-purple-50: #f5f3ff;
$ringOffsetColor-purple-100: #ede9fe;
$ringOffsetColor-purple-200: #ddd6fe;
$ringOffsetColor-purple-300: #c4b5fd;
$ringOffsetColor-purple-400: #a78bfa;
$ringOffsetColor-purple-500: #8b5cf6;
$ringOffsetColor-purple-600: #7c3aed;
$ringOffsetColor-purple-700: #6d28d9;
$ringOffsetColor-purple-800: #5b21b6;
$ringOffsetColor-purple-900: #4c1d95;
$ringOffsetColor-pink-50: #fdf2f8;
$ringOffsetColor-pink-100: #fce7f3;
$ringOffsetColor-pink-200: #fbcfe8;
$ringOffsetColor-pink-300: #f9a8d4;
$ringOffsetColor-pink-400: #f472b6;
$ringOffsetColor-pink-500: #ec4899;
$ringOffsetColor-pink-600: #db2777;
$ringOffsetColor-pink-700: #be185d;
$ringOffsetColor-pink-800: #9d174d;
$ringOffsetColor-pink-900: #831843;

$ringOffsetWidth-0: 0px;
$ringOffsetWidth-1: 1px;
$ringOffsetWidth-2: 2px;
$ringOffsetWidth-4: 4px;
$ringOffsetWidth-8: 8px;

$ringOpacity-0: 0;
$ringOpacity-5: 0.05;
$ringOpacity-10: 0.1;
$ringOpacity-20: 0.2;
$ringOpacity-25: 0.25;
$ringOpacity-30: 0.3;
$ringOpacity-40: 0.4;
$ringOpacity-50: 0.5;
$ringOpacity-60: 0.6;
$ringOpacity-70: 0.7;
$ringOpacity-75: 0.75;
$ringOpacity-80: 0.8;
$ringOpacity-90: 0.9;
$ringOpacity-95: 0.95;
$ringOpacity-100: 1;
$ringOpacity-DEFAULT: 0.5;

$ringWidth-0: 0px;
$ringWidth-1: 1px;
$ringWidth-2: 2px;
$ringWidth-4: 4px;
$ringWidth-8: 8px;
$ringWidth-DEFAULT: 3px;

$rotate-0: 0deg;
$rotate-1: 1deg;
$rotate-2: 2deg;
$rotate-3: 3deg;
$rotate-6: 6deg;
$rotate-12: 12deg;
$rotate-45: 45deg;
$rotate-90: 90deg;
$rotate-180: 180deg;
$rotate-270: 270deg;

$scale-0: 0;
$scale-50: .5;
$scale-75: .75;
$scale-90: .9;
$scale-95: .95;
$scale-100: 1;
$scale-105: 1.05;
$scale-110: 1.1;
$scale-125: 1.25;
$scale-150: 1.5;

$skew-0: 0deg;
$skew-1: 1deg;
$skew-2: 2deg;
$skew-3: 3deg;
$skew-6: 6deg;
$skew-12: 12deg;
$skew--12: -12deg;
$skew--6: -6deg;
$skew--3: -3deg;
$skew--2: -2deg;
$skew--1: -1deg;

$space-0: 0px;
$space-1: 0.25rem;
$space-2: 0.5rem;
$space-3: 0.75rem;
$space-4: 1rem;
$space-5: 1.25rem;
$space-6: 1.5rem;
$space-7: 1.75rem;
$space-8: 2rem;
$space-9: 2.25rem;
$space-10: 2.5rem;
$space-11: 2.75rem;
$space-12: 3rem;
$space-14: 3.5rem;
$space-16: 4rem;
$space-20: 5rem;
$space-24: 6rem;
$space-28: 7rem;
$space-32: 8rem;
$space-36: 9rem;
$space-40: 10rem;
$space-44: 11rem;
$space-48: 12rem;
$space-52: 13rem;
$space-56: 14rem;
$space-60: 15rem;
$space-64: 16rem;
$space-72: 18rem;
$space-80: 20rem;
$space-96: 24rem;
$space-px: 1px;
$space-0-5: 0.125rem;
$space-1-5: 0.375rem;
$space-2-5: 0.625rem;
$space-3-5: 0.875rem;
$space-88px: 5.5rem;
$space-284px: 17.75rem;
$space-100px: 6.25rem;
$space--0: 0px;
$space--1: -0.25rem;
$space--2: -0.5rem;
$space--3: -0.75rem;
$space--4: -1rem;
$space--5: -1.25rem;
$space--6: -1.5rem;
$space--7: -1.75rem;
$space--8: -2rem;
$space--9: -2.25rem;
$space--10: -2.5rem;
$space--11: -2.75rem;
$space--12: -3rem;
$space--14: -3.5rem;
$space--16: -4rem;
$space--20: -5rem;
$space--24: -6rem;
$space--28: -7rem;
$space--32: -8rem;
$space--36: -9rem;
$space--40: -10rem;
$space--44: -11rem;
$space--48: -12rem;
$space--52: -13rem;
$space--56: -14rem;
$space--60: -15rem;
$space--64: -16rem;
$space--72: -18rem;
$space--80: -20rem;
$space--96: -24rem;
$space--px: -1px;
$space--0-5: -0.125rem;
$space--1-5: -0.375rem;
$space--2-5: -0.625rem;
$space--3-5: -0.875rem;
$space--88px: -5.5rem;
$space--284px: -17.75rem;
$space--100px: -6.25rem;

$stroke-current: currentColor;

$strokeWidth-0: 0;
$strokeWidth-1: 1;
$strokeWidth-2: 2;

$textColor-transparent: transparent;
$textColor-current: currentColor;
$textColor-primary: #ea580c;
$textColor-danger: #DC2626;
$textColor-black: #000;
$textColor-orange-50: #fff7ed;
$textColor-orange-100: #ffedd5;
$textColor-orange-200: #fed7aa;
$textColor-orange-300: #fdba74;
$textColor-orange-400: #fb923c;
$textColor-orange-500: #f97316;
$textColor-orange-600: #ea580c;
$textColor-orange-700: #c2410c;
$textColor-orange-800: #9a3412;
$textColor-orange-900: #7c2d12;
$textColor-white: #fff;
$textColor-gray-50: #f9fafb;
$textColor-gray-100: #f3f4f6;
$textColor-gray-200: #e5e7eb;
$textColor-gray-300: #d1d5db;
$textColor-gray-400: #9ca3af;
$textColor-gray-500: #6b7280;
$textColor-gray-600: #4b5563;
$textColor-gray-700: #374151;
$textColor-gray-800: #1f2937;
$textColor-gray-900: #111827;
$textColor-red-50: #fef2f2;
$textColor-red-100: #fee2e2;
$textColor-red-200: #fecaca;
$textColor-red-300: #fca5a5;
$textColor-red-400: #f87171;
$textColor-red-500: #ef4444;
$textColor-red-600: #dc2626;
$textColor-red-700: #b91c1c;
$textColor-red-800: #991b1b;
$textColor-red-900: #7f1d1d;
$textColor-yellow-50: #fffbeb;
$textColor-yellow-100: #fef3c7;
$textColor-yellow-200: #fde68a;
$textColor-yellow-300: #fcd34d;
$textColor-yellow-400: #fbbf24;
$textColor-yellow-500: #f59e0b;
$textColor-yellow-600: #d97706;
$textColor-yellow-700: #b45309;
$textColor-yellow-800: #92400e;
$textColor-yellow-900: #78350f;
$textColor-green-50: #ecfdf5;
$textColor-green-100: #d1fae5;
$textColor-green-200: #a7f3d0;
$textColor-green-300: #6ee7b7;
$textColor-green-400: #34d399;
$textColor-green-500: #10b981;
$textColor-green-600: #059669;
$textColor-green-700: #047857;
$textColor-green-800: #065f46;
$textColor-green-900: #064e3b;
$textColor-blue-50: #eff6ff;
$textColor-blue-100: #dbeafe;
$textColor-blue-200: #bfdbfe;
$textColor-blue-300: #93c5fd;
$textColor-blue-400: #60a5fa;
$textColor-blue-500: #3b82f6;
$textColor-blue-600: #2563eb;
$textColor-blue-700: #1d4ed8;
$textColor-blue-800: #1e40af;
$textColor-blue-900: #1e3a8a;
$textColor-indigo-50: #eef2ff;
$textColor-indigo-100: #e0e7ff;
$textColor-indigo-200: #c7d2fe;
$textColor-indigo-300: #a5b4fc;
$textColor-indigo-400: #818cf8;
$textColor-indigo-500: #6366f1;
$textColor-indigo-600: #4f46e5;
$textColor-indigo-700: #4338ca;
$textColor-indigo-800: #3730a3;
$textColor-indigo-900: #312e81;
$textColor-purple-50: #f5f3ff;
$textColor-purple-100: #ede9fe;
$textColor-purple-200: #ddd6fe;
$textColor-purple-300: #c4b5fd;
$textColor-purple-400: #a78bfa;
$textColor-purple-500: #8b5cf6;
$textColor-purple-600: #7c3aed;
$textColor-purple-700: #6d28d9;
$textColor-purple-800: #5b21b6;
$textColor-purple-900: #4c1d95;
$textColor-pink-50: #fdf2f8;
$textColor-pink-100: #fce7f3;
$textColor-pink-200: #fbcfe8;
$textColor-pink-300: #f9a8d4;
$textColor-pink-400: #f472b6;
$textColor-pink-500: #ec4899;
$textColor-pink-600: #db2777;
$textColor-pink-700: #be185d;
$textColor-pink-800: #9d174d;
$textColor-pink-900: #831843;

$textOpacity-0: 0;
$textOpacity-5: 0.05;
$textOpacity-10: 0.1;
$textOpacity-20: 0.2;
$textOpacity-25: 0.25;
$textOpacity-30: 0.3;
$textOpacity-40: 0.4;
$textOpacity-50: 0.5;
$textOpacity-60: 0.6;
$textOpacity-70: 0.7;
$textOpacity-75: 0.75;
$textOpacity-80: 0.8;
$textOpacity-90: 0.9;
$textOpacity-95: 0.95;
$textOpacity-100: 1;

$transitionDuration-75: 75ms;
$transitionDuration-100: 100ms;
$transitionDuration-150: 150ms;
$transitionDuration-200: 200ms;
$transitionDuration-300: 300ms;
$transitionDuration-500: 500ms;
$transitionDuration-700: 700ms;
$transitionDuration-1000: 1000ms;
$transitionDuration-DEFAULT: 150ms;

$transitionDelay-75: 75ms;
$transitionDelay-100: 100ms;
$transitionDelay-150: 150ms;
$transitionDelay-200: 200ms;
$transitionDelay-300: 300ms;
$transitionDelay-500: 500ms;
$transitionDelay-700: 700ms;
$transitionDelay-1000: 1000ms;

$transitionProperty-none: none;
$transitionProperty-all: all;
$transitionProperty-DEFAULT: (background-color, border-color, color, fill, stroke, opacity, box-shadow, transform);
$transitionProperty-colors: (background-color, border-color, color, fill, stroke);
$transitionProperty-opacity: opacity;
$transitionProperty-shadow: box-shadow;
$transitionProperty-transform: transform;

$transitionTimingFunction-DEFAULT: (cubic-bezier(0.4, 0, 0.2, 1));
$transitionTimingFunction-linear: linear;
$transitionTimingFunction-in: (cubic-bezier(0.4, 0, 1, 1));
$transitionTimingFunction-out: (cubic-bezier(0, 0, 0.2, 1));
$transitionTimingFunction-in-out: (cubic-bezier(0.4, 0, 0.2, 1));

$translate-0: 0px;
$translate-1: 0.25rem;
$translate-2: 0.5rem;
$translate-3: 0.75rem;
$translate-4: 1rem;
$translate-5: 1.25rem;
$translate-6: 1.5rem;
$translate-7: 1.75rem;
$translate-8: 2rem;
$translate-9: 2.25rem;
$translate-10: 2.5rem;
$translate-11: 2.75rem;
$translate-12: 3rem;
$translate-14: 3.5rem;
$translate-16: 4rem;
$translate-20: 5rem;
$translate-24: 6rem;
$translate-28: 7rem;
$translate-32: 8rem;
$translate-36: 9rem;
$translate-40: 10rem;
$translate-44: 11rem;
$translate-48: 12rem;
$translate-52: 13rem;
$translate-56: 14rem;
$translate-60: 15rem;
$translate-64: 16rem;
$translate-72: 18rem;
$translate-80: 20rem;
$translate-96: 24rem;
$translate-px: 1px;
$translate-0-5: 0.125rem;
$translate-1-5: 0.375rem;
$translate-2-5: 0.625rem;
$translate-3-5: 0.875rem;
$translate-88px: 5.5rem;
$translate-284px: 17.75rem;
$translate-100px: 6.25rem;
$translate--0: 0px;
$translate--1: -0.25rem;
$translate--2: -0.5rem;
$translate--3: -0.75rem;
$translate--4: -1rem;
$translate--5: -1.25rem;
$translate--6: -1.5rem;
$translate--7: -1.75rem;
$translate--8: -2rem;
$translate--9: -2.25rem;
$translate--10: -2.5rem;
$translate--11: -2.75rem;
$translate--12: -3rem;
$translate--14: -3.5rem;
$translate--16: -4rem;
$translate--20: -5rem;
$translate--24: -6rem;
$translate--28: -7rem;
$translate--32: -8rem;
$translate--36: -9rem;
$translate--40: -10rem;
$translate--44: -11rem;
$translate--48: -12rem;
$translate--52: -13rem;
$translate--56: -14rem;
$translate--60: -15rem;
$translate--64: -16rem;
$translate--72: -18rem;
$translate--80: -20rem;
$translate--96: -24rem;
$translate--px: -1px;
$translate--0-5: -0.125rem;
$translate--1-5: -0.375rem;
$translate--2-5: -0.625rem;
$translate--3-5: -0.875rem;
$translate--88px: -5.5rem;
$translate--284px: -17.75rem;
$translate--100px: -6.25rem;
$translate-1\/2: 50%;
$translate-1\/3: 33.333333%;
$translate-2\/3: 66.666667%;
$translate-1\/4: 25%;
$translate-2\/4: 50%;
$translate-3\/4: 75%;
$translate-full: 100%;
$translate--1\/2: -50%;
$translate--1\/3: -33.333333%;
$translate--2\/3: -66.666667%;
$translate--1\/4: -25%;
$translate--2\/4: -50%;
$translate--3\/4: -75%;
$translate--full: -100%;

$width-0: 0px;
$width-1: 0.25rem;
$width-2: 0.5rem;
$width-3: 0.75rem;
$width-4: 1rem;
$width-5: 1.25rem;
$width-6: 1.5rem;
$width-7: 1.75rem;
$width-8: 2rem;
$width-9: 2.25rem;
$width-10: 2.5rem;
$width-11: 2.75rem;
$width-12: 3rem;
$width-14: 3.5rem;
$width-16: 4rem;
$width-20: 5rem;
$width-24: 6rem;
$width-28: 7rem;
$width-32: 8rem;
$width-36: 9rem;
$width-40: 10rem;
$width-44: 11rem;
$width-48: 12rem;
$width-52: 13rem;
$width-56: 14rem;
$width-60: 15rem;
$width-64: 16rem;
$width-72: 18rem;
$width-80: 20rem;
$width-96: 24rem;
$width-auto: auto;
$width-px: 1px;
$width-0-5: 0.125rem;
$width-1-5: 0.375rem;
$width-2-5: 0.625rem;
$width-3-5: 0.875rem;
$width-88px: 5.5rem;
$width-284px: 17.75rem;
$width-100px: 6.25rem;
$width-1\/2: 50%;
$width-1\/3: 33.333333%;
$width-2\/3: 66.666667%;
$width-1\/4: 25%;
$width-2\/4: 50%;
$width-3\/4: 75%;
$width-1\/5: 20%;
$width-2\/5: 40%;
$width-3\/5: 60%;
$width-4\/5: 80%;
$width-1\/6: 16.666667%;
$width-2\/6: 33.333333%;
$width-3\/6: 50%;
$width-4\/6: 66.666667%;
$width-5\/6: 83.333333%;
$width-1\/12: 8.333333%;
$width-2\/12: 16.666667%;
$width-3\/12: 25%;
$width-4\/12: 33.333333%;
$width-5\/12: 41.666667%;
$width-6\/12: 50%;
$width-7\/12: 58.333333%;
$width-8\/12: 66.666667%;
$width-9\/12: 75%;
$width-10\/12: 83.333333%;
$width-11\/12: 91.666667%;
$width-full: 100%;
$width-screen: 100vw;
$width-min: min-content;
$width-max: max-content;

$zIndex-0: 0;
$zIndex-10: 10;
$zIndex-20: 20;
$zIndex-30: 30;
$zIndex-40: 40;
$zIndex-50: 50;
$zIndex-auto: auto;
