// Variables

$font-family: Inter;

$z-index-base: 1;
$z-index-spinner: 1000;
$z-index-fixed-tab: 10; // 1. fixny tab panel
$z-index-field-dropdown: 20; // 2. Select dropdown options
$z-index-fixed-header: 30; // 3. Fixny header
$z-index-color-picker: 100;

$box-shadow-dragging: 0px 27px 63px rgba(254, 132, 43, 0.14), 0px 8.13971px 22.5451px rgba(254, 132, 43, 0.0786), 0px 3.38082px 12.3363px rgba(254, 132, 43, 0.055), 0px 1.22278px 5.85223px rgba(254, 132, 43, 0.0414);
$box-shadow-droppable-element-hover: 0px 0px 16px rgb(254, 132, 43 / 23%), 0px 0px 2.00345px rgb(254, 132, 43 / 35%);

$color-delete-red: #EC0150;
$color-white: #FFFFFF;
$color-orange-600-brand: #FF6B00;
$color-grey-800: #414850;

$borderColor-droppable-element: #707C89;
$borderColor-grey-25: #E3E6E9;
$color-grey-400: #BEC4CA;
$color-grey-700: #5E6872;
$borderColor-grey: #FBFCFE;

$textColor-grey-600: #707C89;
$grey-25: #E3E6E9;
$bg-grey-25: #FBFCFE;
$textColor-success-500: #28BC28;
$textColor-grey-500: #9DA6AF;

$backgroundColor-gray-25: #FBFCFE;
$backgroundColor-success-100: #EDF9EA;
$backgroundColor-orange-100: #FAECC3;
$backgroundColor-orange-500: #EE860A;

$color-main-background: #4d4d4d;
$color-main-secondary: #181818;
$color-main-light: #bfbfbf;

$color-error-red: #D84315;
