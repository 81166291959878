@import "tailwind-variables";
@import "variables";

#root {
	width: 100%;
	height: 100%; }

// namapovane na -> https://tailwindcss.com/docs/font-weight
// font-thin
@font-face {
	font-family: 'Inter';
	font-weight: 100;
	src: local('Inter'), url('../assets/fonts/Inter-Thin.ttf') format('truetype'); }
// font-extralight
@font-face {
	font-family: 'Inter';
	font-weight: 200;
	src: local('Inter'), url('../assets/fonts/Inter-ExtraLight.ttf') format('truetype'); }
// font-light
@font-face {
	font-family: 'Inter';
	font-weight: 300;
	src: local('Inter'), url('../assets/fonts/Inter-Light.ttf') format('truetype'); }
// font-normal
@font-face {
	font-family: 'Inter';
	font-weight: 400;
	src: local('Inter'), url('../assets/fonts/Inter-Regular.ttf') format('truetype'); }
// font-medium
@font-face {
	font-family: 'Inter';
	font-weight: 500;
	src: local('Inter'), url('../assets/fonts/Inter-Medium.ttf') format('truetype'); }
// font-semibold
@font-face {
	font-family: 'Inter';
	font-weight: 600;
	src: local('Inter'), url('../assets/fonts/Inter-SemiBold.ttf') format('truetype'); }
// font-bold
@font-face {
	font-family: 'Inter';
	font-weight: 700;
	src: local('Inter'), url('../assets/fonts/Inter-Bold.ttf') format('truetype'); }
// font-extrabold
@font-face {
	font-family: 'Inter';
	font-weight: 800;
	src: local('Inter'), url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype'); }
// font-black
@font-face {
	font-family: 'Inter';
	font-weight: 900;
	src: local('Inter'), url('../assets/fonts/Inter-Black.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: normal;
	src: local('Inter'), url('../assets/fonts/Inter-Regular.ttf') format('truetype'); }

.ant-pagination-item-active:hover a {
	color: $textColor-orange-600; }

.ant-pagination-item-active a {
	color: $textColor-orange-600; }

.ant-pagination-item-active {
	border-color: $textColor-orange-600; }

.ant-pagination-item-active:hover {
	border-color: $textColor-orange-600; }

.ant-table-column-sorter-up.active {
	color: $textColor-orange-600; }

.ant-table-column-sorter-down.active {
	color: $textColor-orange-600; }

.ant-table-container {
	border-radius: 1px;
	border: 1px solid;
	padding: 0px;
	border-color: $grey-25; }

.ant-input {
	background-color: $backgroundColor-gray-100; }

body {
	background-color: $backgroundColor-gray-100; }

.ant-input-affix-wrapper {
	background-color: $backgroundColor-gray-100; }

.ant-form-item-control-input-content {
	border-color: $grey-25; }

.bg-gray-25 {
	background-color: $bg-grey-25; }

.ant-spin-dot-item {
	background-color: $textColor-orange-600; }

.droppable-element-icon-box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px;
	position: static;
	width: 40px;
	height: 40px;
	left: 36px;
	top: 4px;
	background: $backgroundColor-gray-50;
	border-radius: 4px;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px; }

.droppable-element-description {
	position: static;
	width: 198px;
	height: 12px;
	left: 0px;
	top: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0.5px;
	color: $textColor-gray-500;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 0px; }

ul.ul-droppable-element {
	list-style-type: none;
	margin: 0;
	padding: 0; }

span.ant-input-affix-wrapper.list-search-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 16px;
	left: 20px;
	top: 16px;
	background: $backgroundColor-gray-100;
	border: 1px solid $borderColor-gray-300;
	box-sizing: border-box;
	border-radius: 8px;
	width: 270px; }

span.ant-input-affix-wrapper.list-search-input input.ant-input {
	background: $backgroundColor-gray-100; }

.droppable-element-list-menu .ant-tabs.ant-tabs-top.ant-tabs-centered {
	margin-top: 20px; }

.droppable-element-list-menu .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
	right: 75px; }

span.ant-input-prefix {
	color: #BEC4CA; }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $textColor-gray-900; }

.droppable-element-list-menu {
	border-right: 1px solid $borderColor-gray-300; }

.droppable-element:hover {
	//box-shadow: $box-shadow-droppable-element-hover
	border-radius: 4px; }

.droppable-element-list-menu .ant-tabs-content.ant-tabs-content-top {
	height: 100vh; }

.react-grid-layout {
	position: relative;
	transition: height 200ms ease; }

.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;

	&.cssTransforms {
		transition-property: transform; }

	&.resizing {
		z-index: $z-index-base;
		will-change: width, height; }

	&.react-draggable-dragging {
		transition: none;
		z-index: 3;
		will-change: transform; }

	&.react-grid-placeholder {
		background: #ff000075;
		opacity: 0.2;
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none; }

	> .react-resizable-handle {

		.react-grid-layout {
			position: relative;
			transition: height 200ms ease; } } }

.react-grid-item:hover {
	border: 1px solid $color-orange-600-brand !important;
	box-shadow: $box-shadow-dragging; }

.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
	border-radius: 4px;

	img {
		pointer-events: none;
		user-select: none; }

	&.cssTransforms {
		transition-property: transform; }

	&.resizing {
		z-index: $z-index-base;
		will-change: width, height; }

	&.react-draggable-dragging {
		transition: none;
		z-index: 3;
		will-change: transform;
		border: 1px solid $color-orange-600-brand !important;
		box-shadow: $box-shadow-dragging; }

	&.dropping {
		visibility: hidden; }

	&.react-grid-placeholder {
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none; }

	> .react-resizable-handle {
		position: absolute;
		width: 20px;
		height: 20px;

		&::after {
			content: ""; } } }

.react-resizable-hide > .react-resizable-handle {
	display: none; }

.react-grid-item > .react-resizable-handle {
	&.react-resizable-handle-sw {
		bottom: 0;
		left: 0;
		cursor: sw-resize;
		transform: rotate(90deg); }

	&.react-resizable-handle-se {
		bottom: 0;
		right: 0;
		cursor: se-resize; }

	&.react-resizable-handle-nw {
		top: 0;
		left: 0;
		cursor: nw-resize;
		transform: rotate(180deg); }

	&.react-resizable-handle-ne {
		top: 0;
		right: 0;
		cursor: ne-resize;
		transform: rotate(270deg); }

	&.react-resizable-handle-w, &.react-resizable-handle-e {
		top: 50%;
		margin-top: -10px;
		cursor: ew-resize; }

	&.react-resizable-handle-w {
		left: 0;
		transform: rotate(135deg); }

	&.react-resizable-handle-e {
		right: 0;
		transform: rotate(315deg); }

	&.react-resizable-handle-n, &.react-resizable-handle-s {
		left: 50%;
		margin-left: -10px;
		cursor: ns-resize; }

	&.react-resizable-handle-n {
		top: 0;
		transform: rotate(225deg); }

	&.react-resizable-handle-s {
		bottom: 0;
		transform: rotate(45deg); } }

.columns {
	-moz-columns: 120px;
	-webkit-columns: 120px;
	columns: 120px; }

.react-grid-item {
	display: table;
	box-sizing: border-box;

	&:not(.react-grid-placeholder) {
		background: $backgroundColor-white;
		border: 1px solid $borderColor-droppable-element;
		border-radius: 4px; }

	&.resizing {
		opacity: 0.9; }

	&.static {}

	.text {
		font-size: 24px;
		text-align: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 24px; }

	.minMax {
		font-size: 12px; }

	.add {
		cursor: pointer; } }

.react-grid-dragHandleExample {
	cursor: move;
	cursor: grab; }

li b {
	font-size: 19px;
	line-height: 14px; }

.element-label-div {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	height: 100%;
	padding: 2px 0px 2px 0px; }

.react-grid-layout.layout.main-builder {
	height: 100vh !important; }

.element-icon-box {
	width: 24px;
	height: 24px;
	background: $borderColor-grey-25;
	border-radius: 4px;
	float: right;
	margin-right: 8px; }

.element-trash-icon {
	float: right;
	width: 24px;
	height: 24px;
	display: none;
	background: $borderColor-grey-25;
	border-radius: 4px;
	margin-right: 8px; }

.element-label-div {
	.element-trash-icon svg path {
		stroke: $color-grey-800; } }

.element-edit-icon {
	float: right;
	width: 24px;
	height: 24px;
	display: none;
	background: $borderColor-grey-25;
	border-radius: 4px;
	margin-right: 8px; }

.element-icon-box svg path {
	stroke: $color-grey-800; }

.bg-success {
	background: $backgroundColor-success-100; }

.text-success {
	color: $textColor-success-500; }

.text-primary {
	color: $color-orange-600-brand; }

.button-border-primary {
	border-color: $color-orange-600-brand; }

.element-label-div:hover .element-trash-icon {
	display: block; }

.element-label-div:hover .element-edit-icon {
	display: block; }

.element-label-div label {
	float: left;
	margin-left: 10px; }

.react-grid-item.opened-element.react-draggable.cssTransforms.react-resizable {
	border: 1px solid $color-orange-600-brand; }

.element-trash-icon svg path {
	stroke: $color-white; }

header.ant-layout-header {
	background: $backgroundColor-gray-25;
	border-bottom: 1px solid $borderColor-gray-300;
	padding: 0; }

.form-builder-header {
	width: 100%;
	height: 100%;
	display: flex; }

.left-box-form-builder-header {
	max-width: 310px;
	min-width: 310px;
	border-right: 1px solid $borderColor-gray-300;
	display: flex; }

.middle-box-form-builder-header {
	height: 100%;
	min-width: 820px;
	flex: 1;
	display: flex;
	justify-content: space-between; }

.right-box-form-builder-header {
	width: 310px;
	height: 100%;
	border-left: 1px solid $borderColor-gray-300;
	display: flex;
	float: right;
	.ant-btn > span {
		display: flex;
		justify-content: center; } }

.ant-dropdown-trigger.header-user-dropdown-box {
	width: 270px;
	height: 56px;
	line-height: 56px;
	border: 1px solid $borderColor-gray-300;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 16px 16px 0px 20px;
	background: $backgroundColor-white;
	display: flex; }


.left-box-form-builder-header .ant-dropdown-trigger.header-user-dropdown-box svg {
	margin-top: 18px; }
//margin-left: 90px

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
	border-radius: 4px; }

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical li {
	color: $textColor-grey-600; }

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical li svg {
	vertical-align: middle;
	width: 15px; }

.middle-box-form-builder-header .ant-tabs-content-holder {
	display: none; }

.middle-box-form-builder-header {
	ul {
		list-style-type: none;
		padding: 0;
		margin-left: 35px;
		height: 87px;
		margin-bottom: 0px;
		li {
			line-height: 1;
			div {
				height: 24px;
				label {
					font-weight: 500;
					font-size: 18px; }
				.ant-tabs-top > .ant-tabs-nav::before {
					border-bottom: none; } } } } }

li.middle-box-form-builder-header-label {
	margin-top: 16px; }

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
	max-height: 1px; }

li.middle-box-form-builder-header-label svg {
	vertical-align: middle; }

li.middle-box-form-builder-header-label {
	height: 24px; }

li.middle-box-form-builder-header-nav {
	margin-top: 24px;
	.ant-tabs-top > .ant-tabs-nav::before {
		border: none; } }

.ant-dropdown-trigger.header-user-dropdown-box span.ant-avatar.ant-avatar-square {
	margin: 8px 0px 0px 8px; }

li.middle-box-form-builder-header-label label {
	vertical-align: middle;
	font-weight: 500;
	font-size: 18px;
	color: $textColor-black; }

.middle-box-form-builder-header button.ant-btn.ant-dropdown-trigger svg {
	vertical-align: middle; }

.middle-box-form-builder-header button.ant-btn.ant-dropdown-trigger {
	margin-top: 30px;
	width: 125px;
	border-radius: 4px; }

.ant-tabs.ant-tabs-top.item-properties-tab .ant-tabs-nav-list {
	margin-left: 19px; }

.react-grid-item.react-draggable.cssTransforms.react-resizable .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add button.ant-tabs-nav-add {
	background: transparent;
	border: none; }

.react-grid-item.tab-box.static.cssTransforms.react-resizable-hide.react-resizable {
	.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add button.ant-tabs-nav-add {
		background: transparent;
		border: none; }
	.ant-tabs-top > .ant-tabs-nav::before {
		border-bottom: 1px solid $textColor-gray-300; } }

.react-grid-item.react-draggable.cssTransforms.react-resizable .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add button.ant-tabs-nav-add span svg path {
	stroke: $textColor-grey-600; }

.react-grid-item.tab-box.react-draggable.cssTransforms.react-resizable:hover {
	border: none !important; }

.react-grid-item.tab-box.static.cssTransforms.react-resizable-hide.react-resizable:hover {
	border: none !important; }

.react-grid-item.tab-box.react-draggable.cssTransforms.react-resizable {
	border: none;
	background: transparent;
	.ant-tabs-top > .ant-tabs-nav::before {
		border-bottom: 1px solid $textColor-gray-300; } }

.react-grid-item.tab-box.static.cssTransforms.react-resizable-hide.react-resizable {
	border: none;
	background: transparent; }

button.ant-btn.ant-btn-primary.ant-btn-lg.ant-btn-icon-only.lock-button {
	z-index: 3;
	position: absolute;
	right: 0;
	border-radius: 4px 4px 0px 0px; }

button.ant-btn.ant-btn-primary.ant-btn-lg.ant-btn-icon-only.lock-button svg path {
	stroke: $color-white; }

.ant-tabs.ant-tabs-top.ant-tabs-editable.ant-tabs-card.ant-tabs-editable-card {
	.ant-tabs-nav {
		margin: 0;
		.ant-tabs-tab {
			margin: 0;
			border: 1px solid $textColor-grey-600;
			border-radius: 4px 4px 0px 0px;
			background: $color-white; }
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				button.ant-tabs-nav-add {
					color: $textColor-grey-600; }
				.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
					background: $color-orange-600-brand;
					color: $color-white;
					border-radius: 4px 4px 0px 0px;
					border-top: 1px solid $color-orange-600-brand;
					border-left: 1px solid $color-orange-600-brand;
					border-right: 1px solid $color-orange-600-brand;
					border-bottom: 1px solid $textColor-grey-600;
					button.ant-tabs-tab-remove span.anticon.anticon-close svg path {
						color: $color-white; } }
				.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
					color: $color-white; } } } } }

section.ant-layout.ant-layout-has-sider {
	background: $color-white; }

aside.ant-layout-sider.ant-layout-sider-dark.settings-sider {
	.ant-layout-sider-children {
		background: $color-white; } }

main.ant-layout-content.settings-contect {
	margin-top: 48px;
	min-width: 820px; }

.ant-select.dropDown.ant-select-single.ant-select-show-arrow {
	.ant-select-selector {
		border-radius: 6px;
		border: 1px solid $borderColor-grey-25; } }

.ant-select-arrow {
	top: 40%;
	width: 20px;
	height: 20px;
	svg path {
		stroke: $color-grey-400; } }

button.unfilled-btn {
	color: $color-orange-600-brand;
	border: 1px solid $color-orange-600-brand;
	border-radius: 4px;
	svg path {
		stroke: $color-orange-600-brand; } }

button#unfilled-btn-setting {
	margin-left: 20px; }

.ant-modal-header {
	border-radius: 8px 8px 0 0; }

.ant-modal-content {
	border-radius: 8px; }

input.ant-input {
	border-radius: 6px; }

.template-setting-form {
	margin-left: 20px;
	margin-right: 20px; }

.btn-error-text-color {
	color: $backgroundColor-red-600; }

.btn-error-text-color:hover {
	color: $backgroundColor-red-600; }

.btn-error-background-color {
	background: $backgroundColor-red-600; }

.btn-error-background-color:hover {
	background: $backgroundColor-red-600; }

/* TODO - for development */
.layout-border {
 }	// border: 1px solid black !important

.form-builder-page {
	display: grid;
	grid-template-columns: 310px auto 310px; }

.items-left-sidebar {
	grid-row: 1;
	grid-column: 1 / 2;
	background: $backgroundColor-gray-25; }

.main-builder {
	grid-row: 1;
	grid-column: 2 / 3;
	background: $color-white; }

.items-properties-right-sidebar {
	grid-row: 1;
	grid-column: 3 / 4;
	background: $backgroundColor-gray-25; }

aside.items-left-sidebar {
	.droppable-element-list-menu {
		border-left: 1px solid $borderColor-gray-300; } }

aside.items-properties-right-sidebar {
	border-left: 1px solid $borderColor-gray-300;
	.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
		padding-top: 0;
		padding-bottom: 0; }
	.ant-form-item {
		margin-bottom: 0; }
	.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
		padding: 10px 16px;
		padding-right: 40px; }
	.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
		padding: 0; }
	.ant-radio-group.ant-radio-group-outline.form-type-radio-btn-group.w-full {
		height: 32px;
		border: 1px solid $grey-25;
		border-radius: 6px; }
	.ant-collapse-ghost .form-type-radio-btn-group label.ant-radio-button-wrapper {
		height: 30px; }
	.ant-input {
		background: $color-white;
		border: 1px solid $grey-25; }
	.ant-input-affix-wrapper {
		background: $color-white;
		border: 1px solid $grey-25;
		.ant-input {
			border: none; } }
	label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
		span {
			color: $color-white !important;
			svg path {
				stroke: $color-white; } } }
	button.ant-switch {
		background-color: $grey-25; }
	.ant-switch-checked {
		background-color: $color-orange-600-brand !important; } }

.middle-box-form-builder-header button.ant-btn.ant-dropdown-trigger {
	margin-right: 34px; }

div#main-builder-1 {
	min-width: 772px;
	margin: 20px 24px 0 24px; }

.react-grid-layout.layout.main-builder {
	background: repeating-linear-gradient(to right, $color-white, $color-white 10px, $backgroundColor-gray-25 10px, $backgroundColor-gray-25 8.27777775%); }

.ant-modal-header {
	height: 64px;
	border-bottom: none; }

.ant-modal-footer {
	height: 88px;
	border-top: none;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 24px; }

textarea.ant-input.tp-textarea {
	border-radius: 6px; }

.form-type-radio-btn-group {
	display: flex;
	label.ant-radio-button-wrapper {
		display: flex;
		flex: 1 1;
		justify-content: center;
		border-radius: 6px;
		border: 1px solid $borderColor-grey-25;
		color: $textColor-grey-500; }
	.ant-radio-button-wrapper-checked span span.type-check-box {
		border: none;
		border-radius: 4px;
		background: $color-orange-600-brand url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMTNMOSAxN0wxOSA3IiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+);
		background-size: 16px 16px; }
	label {
		span {
			display: flex;
			span.type-check-box {
				display: flex;
				width: 16px;
				height: 16px;
				margin-top: 11px;
				margin-bottom: 11px;
				margin-right: 5px;
				border: 1px solid #E3E6E9;
				border-radius: 2px; } } }
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		display: none; }
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		border: 1px solid $color-orange-600-brand; }
	span.ant-radio-button.ant-radio-button-checked {
		border-radius: 6px;
		background: $backgroundColor-orange-100; }
	.ant-radio-button-wrapper:not(:first-child)::before {
		display: none; } }

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	color: $color-orange-600-brand; }

.hide-btn {
	display: none; }

.ant-message {
	position: absolute;
	bottom: 20px !important;
	top: unset !important; }

.ant-message-notice-content {
	border-radius: 8px; }

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
	color: $color-grey-400;
	background: $grey-25;
	border: none;
	svg path {
		stroke: $color-grey-400; } }

.ant-message-custom-content.ant-message-info {
	display: flex;
	svg path {
		stroke: $color-orange-600-brand; } }

.ant-collapse-header {
	border-top: 1px solid $grey-25; }

.ant-collapse-ghost {
	.form-type-radio-btn-group {
		label span {
			justify-content: center; }
		label.ant-radio-button-wrapper {
			padding: 0;
			border: none;
			span.ant-radio-button.ant-radio-button-checked {
				border-radius: 0px;
				background: $color-orange-600-brand;
				border: none; }
			span svg {
				margin-top: 4px;
				margin-bottom: 4px; } } }
	.ant-radio-group-outline.form-type-radio-btn-group {
		border: 1px solid $borderColor-grey-25;
		border-radius: 6px; } }

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radio-group-btn-right span.ant-radio-button.ant-radio-button-checked {
	border-radius: 0px 6px 6px 0; }

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radio-group-btn-left span.ant-radio-button.ant-radio-button-checked {
	border-radius: 6px 0 0 6px; }

.ant-collapse-item.ant-collapse-item-active {
	.ant-collapse-header svg path {
		transform: rotate(180deg);
		transform-origin: 50% 50%; } }

span.ant-input-affix-wrapper {
	border-radius: 6px; }

.ant-radio-group.ant-radio-group-outline.ant-radio-group-medium.form-type-radio-btn-group label span {
	color: $color-grey-700; }

.color-picker-palette {
	display: contents;
	.chrome-picker {
		width: 126.95px !important;
		border-radius: 5px !important; }
	input {
		border-radius: 5px !important; } }

.color-picker-color-background {
	height: 24px;
	width: 24px;
	border-radius: 5px;
	margin-top: 4px;
	margin-left: 4px;
	position: absolute;
	float: left;
	z-index: $z-index-base; }

.color-picker-cover {
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px; }


// Clean
.flex-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%; }

.primary {
	background: $color-main-background; }

.secondary {
	background: $color-main-secondary; }


.login-logo {
	height: 80px;
	background-image: url('../assets/images/logos/umyjeme_logo.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 30px; }

.app-logo {
	height: 48px;
	background-image: url('../assets/images/logos/umyjeme_logo.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 8px; }

.app-logo-qr {
	height: 70px; }

.loginTextField {
	background: $color-main-light;
	border: none; }

.input-wrapper {
	margin-bottom: 10px;

	label {
		font-weight: bold;

		&.required:after {
			color: $color-error-red;
			content: " *"; } }

	.disabled {
		border: none; }

	.action-buttons {
		margin: 10px 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end; }

	.circle-picker {
		width: 100% !important; } }

.tooltip-error {
	color: $color-error-red; }


.page-wrapper {
	margin-top: 2rem;
	.avatar-uploader > .ant-upload {
		max-width: 128px;
		max-height: 128px; }

	img.upload-image {
		width: 100%;
		max-width: 84px;
		max-height: 84px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain; }

	.translations {
		margin-top: 10px; }

	.grid {
		.main-content {
			background-color: white;
			padding: 20px; }

		.sidebar-content {
			background-color: white;
			padding: 20px; } } }

.flex {
	display: flex;
	&.direction-row {
		flex-direction: row; }
	&.direction-col {
		flex-direction: column; }
	&.justify-center {
		justify-content: center; }
	&.justify-start {
		justify-content: flex-start; }
	&.justify-end {
		justify-content: flex-end; }
	&.justify-between {
		justify-content: space-between; }
	&.align-start {
		align-items: start; }
	&.align-center {
		align-items: center; }
	&.align-end {
		align-items: end; }
	&.wrap {
		flex-flow: wrap; } }

.jobs-container {
	padding: 2em 10px;
	display: grid;
	grid-gap: 8px;
	margin: 2px;
	max-width: 100%;
	max-height: 100%;
	border: 0;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }

.fields-container {
	padding: 0em 10px;
	display: grid;
	grid-gap: 8px;
	margin: 2px;
	max-width: 100%;
	max-height: 100%;
	border: 0;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }

.store-container {
	padding: 2em 10px;
	display: grid;
	grid-gap: 8px;
	margin: 2px;
	max-width: 100%;
	max-height: 100%;
	border: 0;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) {
 } }    // Change "auto-fit" to "auto-fill" above to see the difference

.site-layout-background {
	background: #fff; }

.not-found-page {
	.image-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.not-found-image {
			height: 400px;
			width: 550px;
			background: center no-repeat; }

		.title {
			font-size: 40px;
			margin-bottom: 40px; }


		.redirect-button {
			margin-bottom: 40px; } } }

// draggable parte
// https://ant.design/components/table/#components-table-demo-drag-sorting
.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc; }

.row-dragging td {
	padding: 16px; }

.row-dragging .drag-visible {
	visibility: visible; }
// draggable parte END

.height1 > td {
	height: 1px; }

.red-row {
	background-color: #ffe8e8; }


